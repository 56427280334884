import React, { useEffect, useState, useRef } from "react";
import { BsPerson } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/CSS.css";
import defaultProfilePic from "../../src/pages/components/assets/icons/person.png";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlineAttachEmail } from "react-icons/md";
import Contactprofile from "../../src/pages/components/images/Asset 70mdpi.png";
import axios from "axios";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import { FaCamera, FaEdit, FaLock } from "react-icons/fa";
import { GrContactInfo } from "react-icons/gr";

const User_profile = ({ user }) => {
console.log("USERDATA:",user);
  const [editableFields, setEditableFields] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [pic, setPic] = useState(user.profilepic);

  const nameInputRef = useRef(null);
  const userId = user.id;
  const [show, setShow] = useState(false); // Set default state to true for testing

  // Effect to handle admin data changes
  useEffect(() => {
    if (user) {
      setAdminData({
        name: user.name,
        email: user.email,
        password: user.password,
        phone: user.phone,
        profilePicture: user.profilePicture,
      });
      setPic( user.profilePicture,)
    
      setEditableFields(false);
    setShow(true);
    }else{
      setShow(false)
    }
   
    // Disable editing when data changes
  }, [user]);

  // Toggle edit mode
  const toggleEdit = () => {
    setEditableFields((prev) => !prev);
    if (!editableFields) {
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
    }
  };

  // Handle file input change
  const handleFileInputChange = (event) => {
    if (!editableFields) return;
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setPic(base64String);
        setAdminData((prevData) => ({ ...prevData, profilePicture: base64String }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle input change
  const handleChange = (e, field) => {
    if (e && e.target) {
      setAdminData((prevData) => ({ ...prevData, [field]: e.target.value }));
    }
  };

  // Handle phone input change
  const handlePhoneChange = (value) => {
    setAdminData((prevData) => ({ ...prevData, phone: value }));
  };

  // Save changes
  const handleSave = async () => {
    await axios
      .put(`${ApiEndPoint}/admin/${userId}`, adminData)
      .then((response) => {
        if (response.status === 200) {
          setAdminData(null);
          setEditableFields(false);
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Delete profile
  const handleDelete = async () => {
    await axios
      .put(`${ApiEndPoint}/adminDelete/${userId}`)
      .then((response) => {
        if (response.status === 200) {
          setAdminData(null);
          setShow(false)
        }
        setEditableFields(false);
        setPic(true);
        // Mark as deleted to trigger cleanup
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const containerStyle = {
    position: 'relative',
    display: 'inline-block',
    width: '100px',
    height: '100px',
    marginTop: '20px',
  };

  const profilePicStyle = {
    width: '100%',
    height: '100%',
    border: '1px solid #FF7640',
    borderRadius: '50%',
    boxShadow: '#85929e 0px 2px 5px',
    backgroundImage: pic
      ? `url(data:image/jpeg;base64,${pic})`
      : `url(${Contactprofile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    position: 'relative',
    overflow: 'hidden',
  };

  const cameraOverlayStyle = {
    display: 'none', // Hidden by default
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#FF7640',
    fontSize: '24px',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1,
   // Center the icon within the overlay
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.querySelector('.camera-overlay').style.display = 'flex';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.querySelector('.camera-overlay').style.display = 'none';
  };

  const handleEdit = () => {
    setEditableFields(!editableFields);
  };

  return (
    <center style={{ width: "100%", height: "100%", position: "relative" }}>
      {/* Close Button at the Top Level */}
      {show===true && (
        <button
          title="Close"
          onClick={() => {
            setAdminData(null);
            setShow(false);
          }}
          style={{
            position: "absolute",
            top: "0px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
            color: "#FF7640",
          }}
        >
          &times;
        </button>
      )}
  
      {show===true? (
        <div style={{ width: "50%", position: "relative" }}>
          {/* Profile Picture */}
          <div style={containerStyle}>
            <div
              className="profile-pic"
              style={profilePicStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => document.getElementById("profilePicInput").click()} // Trigger file input click
            >
              <div className="camera-overlay" style={cameraOverlayStyle}>
                <FaCamera />
              </div>
            </div>
  
            <input
              type="file"
              accept="image/*"
              id="profilePicInput"
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
          </div>
  
          {/* Edit Icon */}
          <div>
            {editableFields ? (
              <FaLock
                onClick={handleEdit}
                style={{
                  cursor: "pointer",
                  marginTop: "5px",
                  color: "#FF7640",
                }}
              />
            ) : (
              <FaEdit
                onClick={handleEdit}
                style={{
                  cursor: "pointer",
                  marginTop: "5px",
                  color: "#FF7640",
                }}
              />
            )}
          </div>
  
          {/* Form Fields */}
          <div className="text-start d-flex flex-column gap-2">
            {/* Name Field */}
            <div>
              <label
                className="form-label fw-bold"
                style={{ marginBottom: "10px", fontSize: "1rem", marginLeft: "3px" }}
              >
                Name
              </label>
              <div className="input-group bg-soft-light rounded-2" style={{ marginTop: "-10px" }}>
                <span className="input-group-text">
                  <BsPerson />
                </span>
                <input
                  style={{
                    borderColor: "#24D366", // Green border for unfocused state
                    boxShadow: "none", // Remove default Bootstrap shadow on focus
                  }}
                  className="form-control-md form-control"
                  value={adminData.name}
                  onChange={(e) => handleChange(e, "name")}
                  id="Name"
                  name="username"
                  placeholder="Enter Name"
                  type="text"
                  title="Please Enter Client Name"
                  required
                  readOnly={!editableFields}
                  onFocus={(e) => {
                    e.target.style.borderColor = "#FF7640"; // Orange border on focus
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#24D366"; // Green border on unfocus
                  }}
                />
              </div>
            </div>
  
            {/* Email Field */}
            <div>
              <label
                className="form-label fw-bold"
                style={{ marginBottom: "10px", fontSize: "1rem", marginLeft: "3px" }}
              >
                Email
              </label>
              <div className="input-group bg-soft-light rounded-2" style={{ marginTop: "-10px" }}>
                <span className="input-group-text">
                  <MdOutlineAttachEmail />
                </span>
                <input
                  style={{
                    borderColor: "#24D366", // Green border for unfocused state
                    boxShadow: "none", // Remove default Bootstrap shadow on focus
                  }}
                  className="form-control-md form-control"
                  value={adminData.email}
                  onChange={(e) => handleChange(e, "email")}
                  id="Email"
                  name="email"
                  placeholder="Enter Email"
                  type="email"
                  title="Please Enter Client Email"
                  required
                  readOnly={!editableFields}
                  onFocus={(e) => {
                    e.target.style.borderColor = "#FF7640"; // Orange border on focus
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#24D366"; // Green border on unfocus
                  }}
                />
              </div>
            </div>
  
            {/* Phone Field */}
            <div>
              <label
                className="form-label fw-bold"
                style={{ marginBottom: "10px", fontSize: "1rem", marginLeft: "3px" }}
              >
                Phone
              </label>
              <PhoneInput
                country={"us"}
                value={adminData.phone}
                onChange={handlePhoneChange}
                disabled={!editableFields}
                inputStyle={{
                  width: "100%",
                  border: "1px solid #24D366",
                  boxShadow: "none",
                  height: "37px",
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = "#FF7640"; // Orange border on focus
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#24D366"; // Green border on unfocus
                }}
              />
            </div>
  
            {/* Password Field */}
            <div>
              <label
                className="form-label fw-bold"
                style={{ marginBottom: "10px", fontSize: "1rem", marginLeft: "3px" }}
              >
                Password
              </label>
              <div className="input-group bg-soft-light rounded-2" style={{ marginTop: "-10px" }}>
                <span className="input-group-text">
                  <RiLockPasswordFill />
                </span>
                <input
                  style={{
                    borderColor: "#24D366", // Green border for unfocused state
                    boxShadow: "none", // Remove default Bootstrap shadow on focus
                  }}
                  className="form-control-md form-control"
                  value={adminData.password}
                  onChange={(e) => handleChange(e, "password")}
                  id="Password"
                  name="password"
                  placeholder="Enter Password"
                  type="password"
                  title="Please Enter Client Password"
                  required
                  readOnly={!editableFields}
                  onFocus={(e) => {
                    e.target.style.borderColor = "#FF7640"; // Orange border on focus
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#24D366"; // Green border on unfocus
                  }}
                />
              </div>
            </div>
          </div>
  
          {/* Save and Delete Buttons */}
          {editableFields && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <button
                onClick={handleSave}
                style={{
                  width: "48%",
                  color: "white",
                  background: "#A66CFF",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Update
              </button>
              <button
                onClick={handleDelete}
                style={{
                  width: "48%",
                  color: "white",
                  background: "#FF7640",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      ) : (
        // Empty Screen when show is false
        <div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
          <GrContactInfo className="fs-1" />
          <div>
          <h4>Admin Detail</h4>
          <p>Click any Admin to view details</p>
          </div>
        </div>
      )}
    </center>
  );
  
  
};

export default User_profile;
