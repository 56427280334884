import { FaCheck, FaCheckDouble, FaClock, FaExclamationCircle,FaSpinner, } from "react-icons/fa";

export const renderStatusIcon = (status) => {
    const iconStyle = { fontSize: "10px" }; // Adjust the fontSize as needed
    
    switch (status) {
      case "sent":
        return <FaCheck style={{...iconStyle,color:"grey"}} title="Sent" />;     
      case "delivered":
        return <FaCheckDouble  style={{...iconStyle,color:"grey"}} title="Delivered" />;
      case "read":
        return <FaCheckDouble  style={{ ...iconStyle, color: "#25D366" }} title="Read" />;
      case "failed":
        return <FaExclamationCircle style={{ ...iconStyle, color: "red" }} title="Failed" />;
      default:
        return <FaClock style={iconStyle} title="Pending" />;
        
       
    }
  };