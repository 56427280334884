import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ImCross } from "react-icons/im";
import style from "../Style/CSS.module.css";
import "../Style/CSS.css";
import { GrContactInfo } from "react-icons/gr";
import axios, { Axios } from "axios";
import { SiMicrosoftexcel } from "react-icons/si";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import ContactFormModal, { UpdateForm } from "../pages/ContactForm/ContactForm";
import UserListWidget from "../pages/chat/widgets/UserListWidget";
import ContactFormModal6 from "../pages/ContactForm/ContactForm6";
import ExcelG from '../pages/components/images/simple excel sheet (2).png';
import ContactIc from '../pages/components/images/Add user.png';

import {
  BsFilter,
  BsPersonAdd,
  BsSortDown,
  BsSortUp,
} from "react-icons/bs";
import { useRef } from "react";
import { FaFilter } from "react-icons/fa";

//Remove This Array when Backend data fetch

const Contacts = (calback) => {
  // const Users = useSelector((state) => state.Data.usersdetail);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const userListRef = useRef(null);
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);

  useEffect(() => {
    if (userListRef.current) {
      const totalHeight = window.innerHeight;

      const userListHeight = userListRef.current.offsetHeight;

      setRemainingHeight(userListHeight);
    }
  }, []);
  //state used get data of map array
  const [selectedUser, setSelectedUser] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const [selectedOption, setSelectedOption] = useState(false);
  const filterRef = useRef(null);
  const handleSortChange = (option) => {
    setShowFilters(false); // Close the dropdown after selecting an option
    // Your sorting logic here based on selected option
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterSheetOpen(false);
        setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);

    };
  }, []);
  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };
  // var users=useSelector((state)=>state.Data.usersdetail)
  const [users, setUsers] = useState([]);


  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, [users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleUserClick = (userData) => {
    console.log("User data:", selectedUser);
    setSelectedUser(userData);
  };


  const handleCrossClick = () => {
    setSelectedUser(null);
  };
  //Search user method and with place of Array varaible backend variable
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setIsFilterSheetOpen(false);
    setSearchQuery(e.target.value);
  };
  const toggleFilterSheet = () => {
    setIsFilterSheetOpen(!isFilterSheetOpen);
  };
  const handleFilterOptionClick = (option) => {
    setSelectedOption(option);
    setIsFilterSheetOpen(false); // Close the filter sheet after selection
  };
  // Filter users based on search query
  const filteredUsers = users
    .filter((user) => {
      // Check if user.name is not null and not undefined
      if (user.name && typeof user.name === "string") {
        const lowerCaseName = user.name.toLowerCase();
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        return lowerCaseName.includes(lowerCaseSearchQuery);
      }
      return false; // Filter out user with null or non-string name
    })
    .sort((a, b) => {
      if (selectedOption === "A-Z") {
        return a.name.localeCompare(b.name); // Sort A-Z
      }
      if (selectedOption === "Z-A") {
        return b.name.localeCompare(a.name); // Sort Z-A
      }
      if (selectedOption === "Old to New") {
        return new Date(a.createdAt) - new Date(b.createdAt); // Sort Old to New
      }
      if (selectedOption === "New to Old") {
        return new Date(b.createdAt) - new Date(a.createdAt); // Sort New to Old
      }
      return 0;
    });
  const userListHeight = `calc(98vh - 104px)`;

  return (
    <div
      className="p-0 m-0"
      style={{
        borderRadius: "0",
        height: "82vh",
        width: "auto",
        backgroundColor: "#ffffff",
        marginLeft: "10px",
        display: "flex", // Ensures that the child divs are flex items
        gap: "16px", // Adds space between the two columns
      }}
    >
      {/* First Column */}
      <div
        className="col-lg-3 col-md-3 ml-1 col-sm-12 border-end p-2 d-flex flex-column h-100 position-relative"
        style={{
          width: '20%',
          borderRadius: '12px', // Adds border-radius to make it card-like
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow for card effect
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <input
            type="search"
            placeholder="Search Client"
            className="form-control"
            onChange={handleSearchChange}
            value={searchQuery}
            style={{
              boxShadow: "none"
            }}
            onFocus={(e) => {
              e.target.style.borderColor = "#FF7640"; // Orange border on focus
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#24D366"; // Green border on unfocus
            }}
          />
          {/* Filter Icon */}
          <div className="filter-container position-relative" ref={filterRef}>
            <div
              className="text-gray cursor-pointer ms-2"
              style={{
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #24D366',
                borderRadius: '50%',
              }}
              onClick={toggleFilterSheet}
            >
              {/* Custom Icon to Toggle the Filter Sheet */}
              <div><FaFilter color="#24D366" /></div>
            </div>
          </div>
        </div>
        <div className="flex-grow-1 overflow-auto">
          {/* User List Content */}
          {filteredUsers.map((val) => (
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilepic}
              color={val.color_code}
            />
          ))}
        </div>
        {/* Filter Sheet */}
        {isFilterSheetOpen && (
          <div
            className="position-absolute bg-white shadow-lg p-3"
            style={{
              top: '50px', // Position it below the search bar
              right: '0',  // Align to the right side of the container
              width: '100%',
              borderRadius: '12px',
              zIndex: '1000',
            }}
          >
            <div
              className="filter-option cursor-pointer p-2"
              onClick={() => handleFilterOptionClick("A-Z")}
            >
              By Name: A-Z
            </div>
            <div
              className="filter-option cursor-pointer p-2"
              onClick={() => handleFilterOptionClick("Z-A")}
            >
              By Name: Z-A
            </div>
            <div
              className="filter-option cursor-pointer p-2"
              onClick={() => handleFilterOptionClick("Old to New")}
            >
              By Date: Oldest to Newest
            </div>
            <div
              className="filter-option cursor-pointer p-2"
              onClick={() => handleFilterOptionClick("New to Old")}
            >
              By Date: Newest to Oldest
            </div>
          </div>
        )}

      </div>

      {/* Second Column */}
      <div
        className="col-lg-9 col-md-9 col-sm-12 d-flex justify-content-center align-items-center h-100"
        style={{
          width: '78%',
          borderRadius: "12px", // Adds border-radius to make it card-like
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow for card effect
        }}
      >
        {selectedUser === null ? (
          <div className="text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <GrContactInfo className="fs-1" />
            <div>
              <h4>Contact Detail</h4>
              <p>Click any contact to view details</p>
            </div>
          </div>
        ) : (
          <div className="text-center d-flex flex-column justify-content-center h-100 w-100">
            <UpdateForm user={selectedUser} />
          </div>
        )}
      </div>
    </div>

  );







};
export default Contacts;
