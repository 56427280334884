import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import { ImUserPlus } from "react-icons/im";
import { RiFileExcel2Fill, RiFileExcel2Line } from "react-icons/ri";

import style from "../Style/CSS.module.css";
import Broadcast from "./Broadcast";
import { Broadcastdata, Rightscreen } from "../Redux Toolkit/slice";
import ChatStyle from "../pages/chat/Chat.module.css";
import { HiOutlineUserGroup } from "react-icons/hi";
import axios from "axios";
import { LuUserPlus } from "react-icons/lu";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import ExcelUploadPopup from "../pages/ContactForm/dropzonComponent";
import ContactFormModal, {
  ContactForm,
} from "../pages/ContactForm/ContactForm";
import BroadCastModal from "../Modal/broadcast_modal";
import execlIcons from "../../src/pages/components/assets/icons/excelIcons.png";
import Excel from "../pages/components/assets/Excel_Sheet.png";
import whiteExcel from "../pages/components/assets/icons/XLS_Sheet.png";
import ExcelG from "../pages/components/images/simple excel sheet (2).png";
import whiteExcelG  from "../pages/components/assets/icons/XLS_Sheet.png";
import ExcelB from "../pages/components/assets/Excel_Sheet.png";
import ContactIc from "../pages/components/assets/New_User.png";
import whiteContactIc from "../pages/components/assets/icons/New_User.png";
import Group from "../pages/components/assets/Group.png";
import whiteGroup from "../pages/components/assets/icons/Group.png";
import CustomDropdown from "../pages/components/customDropdown";
import ExcelUploadPopup2 from "../pages/ContactForm/dropzonComponent2";

const Sendmessage = () => {
  var dispatch = useDispatch();
  const broadcastdata = useSelector((state) => state.Data.broadcastdata);
  const dt = useSelector((state) => state.Data.getTemplate);
  const [error, setError] = useState("");
  const [sent, setSent] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [ShowBroadcastPopup, setShowBroadcastPopup] = useState();
  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);
  const [showBroadCastModal, setBroadCastModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");
  const [isGroupHover,setGroupHover]=useState(false);
  const [isExcelB,setExcelBIHover]=useState(false);
  const [isExcel,setExcelHover]=useState(false)
  const [isContactIc,setContactiIc]=useState(false)


  let id = null;

  const openBroadCastModal = () => {
    setBroadCastModal(true);
  };
  const closeBroadCastModal = () => {
    setBroadCastModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const closeContactModal = () => {
    setshowContactModal(false);
  };

  const openContactModal = () => {
    setshowContactModal(true);
  };

  const handleBroadcastIconClick = () => {
    setShowBroadcastPopup(true); // Set state to true to show File popup
  };

  const fetchBroadcasts = useCallback(async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/broadcasts`);
      console.log("Broadcasts:", response.data);
      dispatch(Broadcastdata(response.data.broadcasts));
    } catch (error) {
      console.error("Error fetching broadcasts:", error);
    }
  }, [dispatch]); // Include dispatch in the dependencies array

  // Call fetchBroadcasts inside useEffect with an empty dependency array
  useEffect(() => {
    fetchBroadcasts(); // Call the fetchBroadcasts function when the component mounts
  }, [fetchBroadcasts]);
  useEffect(() => {
    fetchTemplates();
  }, []);
  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getTemplate`);
      setMessageTemplates(response.data.data);
      if (Array.isArray(response.data.data)) {
        const matchedTemplate = response.data.data.find((e) => e.name === dt);
        if (matchedTemplate) {
          setSelectedTemplate(matchedTemplate);
        }
      }
      console.log("Template Messages:", response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };
  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleSendMessage = async () => {
    if (!selectedTemplate) {
      setError(
        "Please select a template and at least one user to send the message."
      );
      setSent("");
      return;
    }

    try {
      if (contactType === "broadcast") {
        console.log("Selected Template:", selectedTemplate);
        const response = await axios.post(`${ApiEndPoint}/send-messages`, {
          templateName: selectedTemplate.name,
          languageCode: selectedTemplate.language,
          broadcasts: selectedBroadcasts,
        });
        if (response.status === 200) {
          setSent("Message sent successfully ");
          setError("");
          setButtonDisabled(false);
        }
      }
      if (contactType === "selectContact") {
        const response = await axios.post(`${ApiEndPoint}/send-messages`, {
          templateName: selectedTemplate.name,
          languageCode: selectedTemplate.language,
          listOfNumbers: selectedUsers.map((user) => ({
            phoneNumber: user.phone,
          })),
        });
        if (response.status === 200) {
          setSent("Message sent successfully ");
          setError("");
          setButtonDisabled(false);
        }
      }

      // Optionally, you can update UI or show a success message here
    } catch (error) {
      console.error("Error sending message:", error);
      setButtonDisabled(false);
      // Optionally, you can update UI or show an error message here
    }
  };

  const handleTemplateChange = (templateId) => {
    const selectedTemplate = messageTemplates.find(
      (template) => template.id === templateId
    );
    console.log(selectedTemplate);
    setSelectedTemplate(selectedTemplate);
  };
  const handleCheckbox = (user, isChecked) => {
    const updatedSelectedUsers = isChecked
      ? [...selectedUsers, { id: user.id, phone: user.phone }]
      : selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
    setSelectedUsers(updatedSelectedUsers);
  };
  const handleBroadcast = (val, isChecked) => {
    const updatedSelectedBroadcasts = isChecked
      ? [...selectedBroadcasts, val]
      : selectedBroadcasts.filter((selected) => selected.id !== val.id);
    setSelectedBroadcasts(updatedSelectedBroadcasts);
  };

  const isSelected = (val) => {
    return selectedBroadcasts.some((item) => item.id === val.id);
  };

  const [contactType, setcontactType] = useState("");
  const SelectContactList = (e) => {
    setcontactType(e.target.value);
  };
  const handleSubmit = () => {
    setButtonDisabled(true);
    handleSendMessage();
  };
  const filteredUsers = users.filter((user) => {
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery2.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false;
  });
  const sortedUsers = filteredUsers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const filteredUsers2 = broadcastdata.filter((user) => {
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery3.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false;
  });
  const sortedUsers2 = filteredUsers2.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchChange2 = (e) => {
    setSearchQuery2(e.target.value);
  };
  const handleSearchChange3 = (e) => {
    setSearchQuery3(e.target.value);
  };
  return (
    <div
      className={`d-flex col-12  justify-content-center`}
      style={{ width: "100%", backgroundColor: "white" }}
    >
      {/* Title */}

      <div
        style={{ width: "50%" }}
        className={` col-12 w-70 h-80 d-flex flex-column  gap-3  mt-1  rounded p-4`}
      >
        <div>
          <CustomDropdown
            selectedTemplate={selectedTemplate}
            messageTemplates={messageTemplates}
            handleTemplateChange={handleTemplateChange}
          />
        </div>

        <div className="gap-2 p-3 d-flex flex-column input">
          <div className=" d-flex w-100 justify-content-between">
            <div
              className="gap-1 d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => document.getElementById("broadcast").click()}
            >
              <input
                type="radio"
                id="broadcast"
                name="contentType"
                value="broadcast"
                style={{ marginTop: -5 }}
                checked={contactType === "broadcast"}
                onChange={SelectContactList}
              />
              <label
                className="fw-sm"
                style={{ cursor: "pointer" }}
                htmlFor="broadcast"
              >
                Broadcast Group(s)
              </label>
            </div>
            <div
              style={{
                display: "inline-block",
                padding: "5px", // Adjust as needed to add some padding around the image
                borderRadius: "5px", // Optional: round corners
                transition: "background-color 0.3s ease", // Smooth transition
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#FF7640";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img
                alt="Add Broadcast"
                src={isGroupHover? whiteGroup:Group}
                style={{
                  height: "20px", // Fixed height
                  width: "20px", // Fixed width
                  objectFit: "contain",
                  // Ensure the image scales correctly within the specified dimensions
                }}
                title="Add Broadcast"
                onClick={() => setShowBroadcastPopup(true)}
                onMouseLeave={(e) => {
                setGroupHover(false)
                  e.target.backgroundColor = "transparent";
                }}
                onMouseEnter={(e) => {
                  setGroupHover(true)
                  e.target.backgroundColor = "#FF7640";
                }}
                // className="fw-bold fs-4 "
              />
            </div>

            <div
              style={{
                display: "inline-block",
                padding: "5px", // Adjust as needed to add some padding around the image
                borderRadius: "5px", // Optional: round corners
                transition: "background-color 0.3s ease", // Smooth transition
              }}
              onMouseEnter={(e) => {
                setExcelBIHover(true)
                e.currentTarget.style.backgroundColor = "#FF7640";
              }}
              onMouseLeave={(e) => {
                setExcelBIHover(false)
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img
                alt="Upload Excel File Only"
                src={isExcelB?whiteExcelG: ExcelB}
                style={{
                  height: "20px", // Fixed height
                  width: "20px", // Fixed width
                  objectFit: "contain", // Ensure the image scales correctly within the specified dimensions
                }}
                className="fw-bold fs-4 "
                title="Upload Excel File Only"
                onClick={openBroadCastModal}
              />
            </div>

            {contactType === "broadcast" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "40%",
                  marginLeft: 30,
                  height: 26,
                }}
              >
                <input
                  className="p-2 rounded w-100 input aliceblue"
                  type="search"
                  placeholder="Search Broadcast"
                  onChange={handleSearchChange3}
                  value={searchQuery3}
                />
              </div>
            )}
          </div>
          {contactType === "broadcast" && (
            <div
              style={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 405px)",
              }}
            >
              {sortedUsers2.map((val, index) => (
                <div key={index} className="mt-4 d-flex">
                  <label className="d-flex align-items-center">
                    <input
                      id={`checkbox-${val.id}`}
                      type="checkbox"
                      checked={selectedBroadcasts.some(
                        (broadcast) => broadcast.id === val.id
                      )}
                      onChange={(e) => handleBroadcast(val, e.target.checked)}
                    />
                    <span className="ms-2 fs-5">{val.name}</span>
                  </label>
                </div>
              ))}
            </div>
          )}

          <hr />
          <div className=" d-flex w-100 justify-content-between">
            <div
              className="gap-1 d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => document.getElementById("selectContact").click()}
            >
              <input
                type="radio"
                id="selectContact"
                name="contentType"
                value="selectContact"
                style={{ marginTop: -5 }}
                checked={contactType === "selectContact"}
                onChange={SelectContactList}
              />
              <label
                className="fw-sm"
                style={{ cursor: "pointer" }}
                htmlFor="selectContact"
              >
                Individual Contact(s)
              </label>
            </div>

            <div
              style={{
                display: "inline-block",
                padding: "5px", // Adjust as needed to add some padding around the image
                borderRadius: "5px", // Optional: round corners
                transition: "background-color 0.3s ease", // Smooth transition
              }}
              onMouseEnter={(e) => {
                setContactiIc(true)
                e.currentTarget.style.backgroundColor = "#FF7640";
              }}
              onMouseLeave={(e) => {
                setContactiIc(false)
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img
                src={isContactIc? whiteContactIc: ContactIc}
                alt="Select Contacts"
                style={{
                  height: "20px", // Fixed height
                  width: "20px", // Fixed width
                  objectFit: "contain", // Ensure the image scales correctly within the specified dimensions
                }}
                onClick={openContactModal}
                title="Select Contacts"
              />
            </div>

            <div
              style={{
                display: "inline-block",
                padding: "5px", // Adjust as needed to add some padding around the image
                borderRadius: "5px", // Optional: round corners
                transition: "background-color 0.3s ease", // Smooth transition
              }}
              onMouseEnter={(e) => {
                setExcelHover(true)
                e.currentTarget.style.backgroundColor = "#FF7640";
              }}
              onMouseLeave={(e) => {
                setExcelHover(false)
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img
                src={isExcel? whiteExcel: Excel}
                alt="Upload Excel File Only"
                style={{
                  height: "20px", // Fixed height
                  width: "20px", // Fixed width
                  objectFit: "contain", // Ensure the image scales correctly within the specified dimensions
                }}
                title="Upload Excel File Only"
                onClick={handleShowModal}
              />
            </div>

            {contactType === "selectContact" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "40%",
                  marginLeft: 30,
                  height: 26,
                }}
              >
                <input
                  className="p-2 rounded w-80 input aliceblue"
                  type="search"
                  placeholder="Search Contacts"
                  onChange={handleSearchChange2}
                  value={searchQuery2}
                />
              </div>
            )}
          </div>
          {contactType === "selectContact" && (
            <div
              style={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 405px)",
              }}
            >
              {sortedUsers &&
                sortedUsers.map((user, index) => (
                  <div
                    className={
                      ChatStyle.userListWidget +
                      " col-12 col-lg-5 col-xl-3 list-group-item list-group-item-action border-0"
                    }
                    style={{ maxHeight: "100%", overflowY: "auto" }}
                  >
                    <label
                      htmlFor={`checkbox-${user.id}`}
                      className="border-0 list-group-item list-group-item-action d-flex align-items-center"
                    >
                      {/* Checkbox input */}
                      <input
                        id={`checkbox-${user.id}`}
                        type="checkbox"
                        checked={selectedUsers.some(
                          (selectedUser) => selectedUser.id === user.id
                        )}
                        onChange={(e) => handleCheckbox(user, e.target.checked)}
                      />
                      {/* User profile information */}
                      <div
                        className="rounded-circle d-flex justify-content-center align-items-center"
                        style={{
                          backgroundImage: user.profilePic
                            ? `url(data:image/jpeg;base64,${user.profilePic})`
                            : "none",
                          backgroundColor: user.profilePic
                            ? "transparent"
                            : user.color_code, // Set background color if profilePic is null
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "40px",
                          height: "40px",
                          margin: "auto",
                          border: `1px solid #FFF`,
                          boxShadow: `none`,
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "auto",
                            textAlign: "center",
                            color: "#FFF",
                          }}
                        >
                          {user && user.profilePic
                            ? ""
                            : user.name &&
                              user.name.length > 0 &&
                              user.name.includes(" ")
                            ? user.name[0] +
                              `${
                                user.name.split(" ").length > 1 &&
                                user.name.split(" ")[1]
                                  ? user.name.split(" ")[1][0]
                                  : ""
                              }`
                            : user.name[0][0]}
                        </div>
                      </div>
                      <div
                        className="flex-grow-1"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {user && user.name} {/* Display user's name */}
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* <button
          className={`${style.btn} w-100 mt-3 mb-3`}
          onClick={handleSubmit}
        >
          Submit
        </button> */}
        <div className="p-1 mt-3 d-flex justify-content-around">
          <button
            onClick={handleSubmit}
            disabled={buttonDisabled} // Disable button when buttonDisabled is true
            style={{
              backgroundColor: buttonDisabled ? "grey" : "#A66CFF", // Set color based on the disabled state
              border: "none",
              color: "white", // Ensure the text color is visible
              cursor: buttonDisabled ? "not-allowed" : "pointer", // Change cursor style when disabled
              transition: "background-color 0.3s ease, transform 0.3s ease",
              fontSize: "16px", // Add smooth transition for color and scale
            }}
            onMouseEnter={(e) => {
              if (!buttonDisabled) {
                // Apply hover effect only if button is not disabled
                e.currentTarget.style.backgroundColor = "#FF7640";
                e.currentTarget.style.transform = "scale(1.05)"; // Add a slight zoom effect on hover
              }
            }}
            onMouseLeave={(e) => {
              if (!buttonDisabled) {
                // Revert to default color only if button is not disabled
                e.currentTarget.style.backgroundColor = "#A66CFF";
                e.currentTarget.style.transform = "scale(1)"; // Revert zoom effect
              }
            }}
            className={`${style.btn} rounded`}
          >
            Send
          </button>
        </div>

        <div className="p-1 d-flex justify-content-center">
          <p style={{ color: "red", fontSize: 17, fontWeight: "bold" }}>
            {error}
          </p>
          <p style={{ color: "green", fontSize: 17, fontWeight: "bold" }}>
            {sent}
          </p>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Excel Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExcelUploadPopup isOpen={showModal} onClose={handleCloseModal} />
          </Modal.Body>
        </Modal>
        {/* Contact Form Modal */}
        <Modal show={showContactModal} onHide={closeContactModal}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContactForm
              isOpen={showContactModal}
              onClose={closeContactModal}
            />
          </Modal.Body>
        </Modal>

        {/* Render Broadcast popup if showBroadcastPopup is true */}

        <Modal show={showBroadCastModal} onHide={closeBroadCastModal}>
          <Modal.Header closeButton>
            <Modal.Title>Excel Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExcelUploadPopup2 isOpen={showModal} onClose={handleCloseModal} />
          </Modal.Body>
        </Modal>
        {ShowBroadcastPopup && (
          <>
            <Modal
              show={true}
              onHide={() => setShowBroadcastPopup(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Create Broadcast</Modal.Title>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%",
                    marginLeft: 10,
                  }}
                >
                  <input
                    className="p-2 rounded w-100 input aliceblue"
                    type="search"
                    placeholder="Search Contacts"
                    onChange={handleSearchChange}
                    value={searchQuery}
                  />
                </div>
              </Modal.Header>
              <Modal.Body>
                <Broadcast searchQuery={searchQuery} />
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};
export default Sendmessage;
