import React, { useEffect, useState } from "react";
import "../Chat.module.css";
import Video from '../../components/images/video call.png';
import Voice from '../../components/images/dialer.png';
import OPT from '../../components/images/options.png';
import { ApiEndPoint } from "../../components/utils/utlis";
import axios from "axios";
export default function ChatHeader({ name, phone, profilePic, color_code,triggerEffect }) {
  const [isHumanActive, setIsHumanActive] = useState(false);

  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const response = await axios.post(`${ApiEndPoint}/getHandOver`, { phone });
        
        if (response.data.isHumanActive !== undefined) {
          setIsHumanActive(response.data.isHumanActive);
          console.log("status code", response.data.isHumanActive);
        }
      } catch (error) {
        console.log('Failed to fetch initial value:', error);
        if (error.response && error.response.status === 404) {
          setIsHumanActive(false);
        }
      }
    };

    // Fetch the initial value immediately
    fetchInitialValue();

    // Set up the interval to fetch every 5 seconds
    const intervalId = setInterval(fetchInitialValue, 5000);

    // Clean up the interval on unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [phone, triggerEffect]);

  const toggleSwitch = async () => {
    const newIsHumanActive = !isHumanActive;
    setIsHumanActive(newIsHumanActive);

    
        const response = await axios.post(`${ApiEndPoint}/handOvertoHuman`, {
          phone,
          isHumanActive: newIsHumanActive,
        });
       
     
    
  };

  return (
    <div
      className="py-0 px-0 d-none d-lg-block mt-1 mb-1"
      style={{
        maxHeight: '9vh',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div className="d-flex align-items-center" style={{ height: '100%',marginLeft: '15px', marginRight: '15px' }}>
        <div
          className="position-relative"
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: profilePic ? `url(data:image/jpeg;base64,${profilePic})` : 'none',
              backgroundColor: profilePic ? 'transparent' : color_code,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: '40px',
              height: '40px',
              border: '1px solid #FFF',
              boxShadow: 'none',
            }}
          >
            <div style={{ margin: 'auto', textAlign: 'center', color: '#FFF' }}>
              {!profilePic && name && name.length > 0
                ? name.split(' ').map(n => n[0]).join('')
                : ''}
            </div>
          </div>
        </div>
        <div className="flex-grow-1 pl-3">
          <strong className="fs-8">{name || 'Name'}</strong>
          <div className="text-muted  fs-20" style={{ fontSize:"10px"}}>{phone ? `+${phone}` : ''}</div>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginRight: '15px' }}>
            <span style={{ color: isHumanActive ? '#ccc' : '#24D366' }}>Switch to Bot</span>
            <label style={{ position: 'relative', width: '40px', height: '20px' }}>
              <input
                type="checkbox"
                style={{ opacity: 0, width: 0, height: 0 }}
                checked={isHumanActive}
                onChange={toggleSwitch}
              />
              <span
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: isHumanActive ? '#24D366' : '#ccc',
                  transition: '.4s',
                  borderRadius: '15px',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    content: '""',
                    height: '14px',
                    width: '14px',
                    left: '3px',
                    bottom: '3px',
                    backgroundColor: 'white',
                    transition: '.4s',
                    borderRadius: '50%',
                    transform: isHumanActive ? 'translateX(20px)' : 'translateX(0)',
                  }}
                ></span>
              </span>
            </label>
            <span style={{ color: isHumanActive ? '#24D366' : '#ccc' }}>Switch to Agent</span>
          </div>
          <button
            className="mr-1"
            style={{ marginRight: '10px', background: 'none', border: 'none', padding: 0 }}
          >
            <img src={Voice} width={25} height={25} alt="Voice" />
          </button>
          <button
            className="mr-1"
            style={{ marginRight: '10px', background: 'none', border: 'none', padding: 0 }}
          >
            <img src={Video} width={25} height={25} alt="Video" />
          </button>
          <button
            style={{ background: 'none', border: 'none', padding: 0 }}
          >
            <img src={OPT} width={25} height={25} alt="Options" />
          </button>
        </div>
      </div>
    </div>
  );
}
